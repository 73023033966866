import React, { useState } from 'react';
import { Card, Button, Typography, Modal, Space, Spin, Row, Col, Input } from 'antd';
import axios from '../../api';
import './mobileVerifyOTP.css';
import { CancelyIcon, VerifyIcon, VerifyDetailsIcon } from '../../assets';

const { Text, Link, Title } = Typography;
const otpRegex = /^\d{6}$/;

const MobileVerifyOTP = ({
  enableRetry,
  timerCount,
  formValues,
  setEnableRetry,
  resendOtp,
  termsAccepted,
  termsData,
  isNewUser,
  isEmailVerified,
  isPhoneVerified,
  enableRetryPhone,
  setEnableRetryPhone,
  timerCountPhone,
}) => {
  const [emailOtp, setEmailOtp] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loaderStatus, setLoaderStatus] = useState(false);
  const { id } = termsData || {};

  const validateOtp = async () => {
    setLoaderStatus(true);
    try {
      // if (!otpRegex.test(emailOtp) || !otpRegex.test(phoneOtp)) {
      //   setErrorMsg('Please enter a 6 digit OTP');
      //   return;
      // }
      let payload = {
        username: formValues.username,
        password: formValues.newPassword,
        is_accepted: termsAccepted,
        terms_and_conditions: id && id.toString(),
      };
      if(!isNewUser || (isNewUser && isEmailVerified === false)){
        payload = {...payload, email_otp: emailOtp}
      }
      if(isNewUser && isPhoneVerified === false)
        payload = {...payload, phone_otp: phoneOtp}

      // api request for validating otp
      const response = await axios.post('/users/validatePasswordReset', payload);
      if (response.status === 200 && response.data.password_reset) {
        errorMsg && setErrorMsg('');
        !enableRetry && setEnableRetry(true);
        !enableRetryPhone && setEnableRetryPhone(true);
        // show password reset success modal
        setShowModal(true);
      }
    } catch (error) {
      if (error && error.error) {
        setErrorMsg(error.error.message);
      } else if (error && error.message) {
        setErrorMsg(error.message);
      }
    }
    setLoaderStatus(false);
  };

  const resendPhoneOtp = async () => {
    try {
      setEnableRetryPhone(false);
      //generate otp only for phone use enhanced api
      let payload = {
        username: formValues.username,
        isPhoneVerified: false,
      };
      await axios.post('/users/generateVerifyOtp', {
        ...payload,
      });
      errorMsg && setErrorMsg('');
    } catch (e) {
      console.log(e);
      if(e.message){
        setErrorMsg('Unable to generate code.');
      }
    }
  };

  const resendEmailOtp = async () => {
    try {
      if (isNewUser) {
        setEnableRetry(false);
        //generate otp only for email use enhanced api
        let payload = {
          username: formValues.username,
          isEmailVerified: false,
        };
        await axios.post('/users/generateVerifyOtp', {
          ...payload,
        });
        errorMsg && setErrorMsg('');
      } else {
        resendOtp();
      }
    } catch (e) {
      console.log(e);
      if(e.message){
        setErrorMsg('Unable to generate code.');
      }
    }
  };

  const handleOTPInput = (e, setInputState) => {
    const { value } = e.target;
    if (value.length < 7) {
      setInputState(value);
    }

  };

  return (
    <React.Fragment>
      <div className="background-screen">
        <Text strong className="verification-heading-cma">
          VERIFY YOUR DETAILS
        </Text>
        <VerifyDetailsIcon className={'verify-icon'} height={52} />
      </div>

      <Spin size="large" spinning={loaderStatus}>
        <Card className={'verify-otp-card-cma'}>
          {!!errorMsg.length && 
          <div className="card-error-container">
            <CancelyIcon className={'cancel-icon'}/>
            <Text className="card-error">
            {errorMsg}
            </Text>
          </div>
          }

          <div className="form-container">
            { isEmailVerified === false && 
              <div className="form-item">
                <Text className="heading">Email</Text>
                <div className="mv-4" />
                <Text className="label">Please enter the verification code sent on email</Text>
                <Input
                  value={emailOtp}
                  placeholder="Enter Verification Code"
                  className="input"
                  onChange={(e) => {
                    handleOTPInput(e, setEmailOtp);
                  }}
                />
                <Text className="sub-label">Didn’t receive a code? &nbsp;</Text>
                {enableRetry ? (
                  <Link className="sub-label resend-otp-txt" onClick={resendEmailOtp}>
                    Resend Code
                  </Link>
                ) : (
                  <Text className="sub-label retry-count-txt">
                    {`Retry in ${timerCount.toString().padStart(2, '0')} sec`}
                  </Text>
                )}
              </div>
            }
            {
              (isPhoneVerified === false && isNewUser) && 
              <div className="form-item">
                <Text className="heading">Phone</Text>
                <div className="mv-4" />
                <Text className="label">Please enter the verification code sent on phone</Text>
                <Input
                  value={phoneOtp}
                  placeholder="Enter Verification Code"
                  className="input"
                  onChange={(e) => handleOTPInput(e, setPhoneOtp)}
                />
                <Text className="sub-label">Didn’t receive a code? &nbsp;</Text>
                {enableRetryPhone ? (
                  <Link className="sub-label resend-otp-txt" onClick={resendPhoneOtp}>
                    Resend Code
                  </Link>
                ) : (
                  <Text className="sub-label retry-count-txt">
                    {`Retry in ${timerCountPhone.toString().padStart(2, '0')} sec`}
                  </Text>
                )}
              </div>
            }
          </div>



          <div className="verifyotp-btn-groups">
            <Button
              type="primary"
              htmlType="button"
              size="medium"
              className={`form-btn submit-otp-btn`}
              disabled={isNewUser ? ((isEmailVerified === false && isPhoneVerified === false) ? 
                (emailOtp.length !== 6 || phoneOtp.length !== 6) : isEmailVerified === false ? emailOtp.length !== 6 : phoneOtp.length !== 6) : emailOtp.length !== 6}
              onClick={validateOtp}
            >
              Submit
            </Button>
          </div>
        </Card>
      </Spin>

      <Modal
        centered={true}
        footer={null}
        closable={false}
        open={showModal}
        className="otp-section-modal"
        width={360}
      >
        <Space direction={'vertical'} className="reset-modal-space">
          <Title level={3} className="reset-modal-title">
            Your password has been reset!
          </Title>
          <Text color="#707070" className="reset-modal-sub-title">
            {
              "To ensure your account's security, you will need to log in to the dashboard with your updated credentials."
            }
          </Text>
        </Space>
      </Modal>
    </React.Fragment>
  );
};

export default MobileVerifyOTP;
