
import "../VerifyOTPNewUser/verifyOTPNewUser.css";
import "./verifyOTPUserCMA.css";
import React, { useState, useEffect } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { Select, Modal, Space, Typography  } from 'antd';
import axios from "../../api";
import { OTPVerificationIcon, SECrossFilledIcon, SEDownArrowIcon } from "../../assets";

const { Text, Link, Title } = Typography;

const VerifyOTPUserCMA = ({formValues, newUserInfo, termsData, termsAccepted, SETheme}) => {

    const [enableOTP, setEnableOTP] = useState({});
    const [otpSource, setOTPSource] = useState("Email")
    const [showTimer, setShowTimer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const [OTP, setOTP] = useState('')
    const [isFirstTime, setIsFirstTime] = useState(true);
    const { id } = termsData || {};
    
    useEffect(() => {
        let intervalId;
        if (showTimer && timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer == 0) {
            setShowTimer(false);
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [showTimer, timer]);

    const handleChange = (value) => {
        setOTPSource(value)
    }

    const sendOTP = async () => {
        try{
            let payload = {
                username: formValues.username
            }
            if(otpSource.toLowerCase() === "email")
                payload = {...payload, isEmailVerified: false}
            else
                payload = {...payload, isPhoneVerified: false}
            const response = await axios.post("/users/generateVerifyOtp", {...payload});
            if (response.status === 200 && response.data.otp_sent){
                setEnableOTP({success: 'OTP sent successfully'});
                setTimer(60); 
                setShowTimer(true)
            }
            else
                setEnableOTP({
                    error: `Unable to generate OTP.`
                });
        } catch(e){
            console.log(e);
            setEnableOTP({
                error: `Unable to generate OTP.`
            });
        }
        setIsFirstTime(false)
    };

    const handleOTPInput = (e) => {
        if(e.target.value.length < 7){
            setOTP(e.target.value)
        }
    }

    const onFinishOTP = async () => {
        try{
            let payload = {
                username: formValues.username,
                password: formValues.newPassword,
                is_accepted: termsAccepted,
                terms_and_conditions: id && id.toString()
            };
            if(otpSource.toLowerCase() === "email")
                payload = {...payload, email_otp: OTP}
            else
                payload = {...payload, phone_otp: OTP}
            // return
            const response = await axios.post("/users/validatePasswordReset", {...payload});
            if (response.status === 200 && response.data.password_reset) {
                setShowModal(true)
            } 
        } catch (error) {
            if (error && error.error && error.error.message) {
                // setErrorMsg(error.error.message);
                setEnableOTP({
                    error: error.error.message
                });
            } else if(error && error.message) {
                // setErrorMsg(error.message);
                setEnableOTP({
                    error: error.message || `Invalid code, please try again.`
                });
            }
        }
    };

    const getOTPFormFields = () => {
        return <>
            <div className='otp-container-login-page'>
                <div className='otp-source-container-cma'>
                    <div style={{fontSize: '15px'}}>
                        Select a verification method
                    </div>
                    <Select
                        defaultValue={otpSource}
                        className='otp-source-dropdown-cma'
                        onChange={value => {setOTPSource(value); setIsFirstTime(true); setEnableOTP({})}}
                        options={(newUserInfo["isNewUser"] && newUserInfo['isEmailVerified'] && newUserInfo['isPhoneVerified']) ? [
                            {
                                value: 'email',
                                label: 'Email',
                            },
                            {
                                value: 'sms',
                                label: 'SMS',
                            },
                        ] : [{
                            value: 'email',
                            label: 'Email',
                        }]}
                        suffixIcon={<SEDownArrowIcon className="se-down-arrow-icon"/>}
                    />
                    {
                        !isFirstTime && <>
                            <div style={{fontSize: '15px', marginTop: '15px'}}>
                                Please enter the verification code
                            </div>
                            <div>
                                <Form.Input fluid iconPosition='left' placeholder='Enter Verification Code' className='code-input-area'
                                    name="OTP" value={OTP} onChange={handleOTPInput} />
                            </div>
                            {
                                !isFirstTime && enableOTP?.error && <div style={{ display: 'flex', alignItems: 'center', gridGap: '5px'}}>
                                    <SECrossFilledIcon /><Title level={5} type="danger" className="err-msg" style={{margin: '0', fontSize: '12px'}}>
                                        {enableOTP?.error}
                                    </Title>
                                </div>
                            }
                            <div style={{textAlign: 'left', fontSize: '12px', position: 'relative', bottom: '10px'}}>
                                <span>Didn’t receive a code? <br/> 
                                    <span className={"additional-trigger-timer-count"} onClick={!showTimer && sendOTP}>
                                        {
                                            showTimer ? <>Retry in {timer} sec </> : 
                                                <>
                                                    Resend Code
                                                </>
                                        }
                                    </span> 
                                </span>
                            </div>
                        </>
                    }
                    <div style={{textAlign: 'center', marginTop: '10px'}}>
                        <Button 
                            id={'otp-source-generate-btn'} 
                            onClick={isFirstTime ? sendOTP : onFinishOTP}
                            style={!isFirstTime ? ((OTP && OTP.length === 6) ? {opacity: '1'} : 
                                {opacity: '0.4', pointerEvents: 'none'}) : {opacity: '1'}}
                        >
                            {
                                isFirstTime ? <>Send OTP</> : <>Next</>
                            }
                        </Button>
                    </div>
                    {
                        isFirstTime && enableOTP?.error && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridGap: '5px'}}>
                            <SECrossFilledIcon /><Title level={5} type="danger" className="err-msg" style={{marginBottom: '25px', fontSize: '12px'}}>
                                {enableOTP?.error}
                            </Title>
                        </div>
                    }
                </div>
            </div>
        </>
    }

    return(<>
            <div className='' style={{height: '100%'}}>
                <div className={"background-screen-verification"}>
                    VERIFY YOUR IDENTITY
                    <OTPVerificationIcon id='otp-page-icon'/> 
                </div>
                    {getOTPFormFields()}
            </div>
            <Modal
                centered={true}
                footer={null}
                closable={false}
                open={showModal}
                className="otp-section-modal"
                width={360}
            >
                <Space direction={"vertical"} className="reset-modal-space">
                    <Title level={3} className="reset-modal-title">
                        Your password has been reset!
                    </Title>
                    <Text color="#707070" className="reset-modal-sub-title">
                        {
                            "To ensure your account's security, you will need to log in to the dashboard with your updated credentials."
                        }
                    </Text>
                </Space>
            </Modal>
        </>
    )
}

export default VerifyOTPUserCMA;