import React, { useEffect, useState } from "react";
import { Card, Button, Typography, Modal, Space, Spin, Row, Col } from "antd";
import OtpInput from "react-otp-input";
import axios from "../../api";
import logo_se from "../../assets/images/logo-se.png";
import "./VerifyOTP.css";

const { Text, Link, Title } = Typography;

const NewVerifyOTP = ({
  formValues,
  resendOtp,
  termsAccepted,
  termsData,
  isNewUser,
  isEmailVerified,
  isPhoneVerified,
  resetEnableRetry
}) => {
  const TIMER_VALUE = 60;
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [enableRetry, setEnableRetry] = useState(false);
  const [enableRetryPhone, setEnableRetryPhone] = useState(false);
  const [timerCount, setTimerCount] = useState(TIMER_VALUE);
  const [timerCountPhone, setTimerCountPhone] = useState(TIMER_VALUE);
  const { id } = termsData || {};

  useEffect(() => {
    !enableRetry && formValues && resendOtpInterval();
  }, [timerCount, enableRetry]);

  useEffect(() => {
    !enableRetryPhone && formValues && resendOtpIntervalPhone();
  }, [timerCountPhone, enableRetryPhone]);

  useEffect(() => {
    if(resetEnableRetry > 0) {
      setEnableRetry(false);
      setEnableRetryPhone(false)
    }
  }, [resetEnableRetry])

  const resendOtpInterval = () => {
    if (timerCount > 0) {
      setTimeout(() => {
        setTimerCount((timerCount) => timerCount - 1);
      }, 1000);
    } else {
      setEnableRetry(true);
      setTimerCount(TIMER_VALUE);
    }
  };

  const resendOtpIntervalPhone = () => {
    if (timerCountPhone > 0) {
      setTimeout(() => {
        setTimerCountPhone((timerCountPhone) => timerCountPhone - 1);
      }, 1000);
    } else {
      setEnableRetryPhone(true);
      setTimerCountPhone(TIMER_VALUE);
    }
  };

  const validateOtp = async () => {
    setLoaderStatus(true);
    try {
      let payload = {
        username: formValues.username,
        password: formValues.newPassword,
        is_accepted: termsAccepted,
        terms_and_conditions: id && id.toString(),
      };
      if(!isNewUser || (isNewUser && isEmailVerified === false)){
        payload = {...payload, email_otp: emailOtp}
      }
      if(isNewUser && isPhoneVerified === false)
        payload = {...payload, phone_otp: phoneOtp}

      // api request for validating otp
      const response = await axios.post(
        "/users/validatePasswordReset",
        payload
      );
      if (response.status === 200 && response.data.password_reset) {
        errorMsg && setErrorMsg("");
        !enableRetry && setEnableRetry(true);
        !enableRetryPhone && setEnableRetryPhone(true);
        // show password reset success modal
        setShowModal(true);
      }
    } catch (error) {
      if (error && error.error) {
        setErrorMsg(error.error.message);
      } else if (error && error.message) {
        setErrorMsg(error.message);
      }
    }
    setLoaderStatus(false);
  };

  const resendPhoneOtp = async () => {
    try {
      setEnableRetryPhone(false);
      //generate otp only for phone use enhanced api
      let payload = {
        username: formValues.username,
        isPhoneVerified: false,
      };
      const response = await axios.post("/users/generateVerifyOtp", {
        ...payload,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const resendEmailOtp = async () => {
    try {
      if (isNewUser) {
        setEnableRetry(false);
        //generate otp only for email use enhanced api
        let payload = {
          username: formValues.username,
          isEmailVerified: false,
        };
        const response = await axios.post("/users/generateVerifyOtp", {
          ...payload,
        });
      } else {
        resendOtp();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Spin size="large" spinning={loaderStatus}>
        <Card className={"SE-theme new-card-wrapper verify-otp-card"}>
          <div>
            <div className="transparent-green-cover"></div>
            <div className="green-cover"></div>
          </div>
          <Row>
            <Col span={17} style={{display: 'flex',}}>
              <img src={logo_se}  style={{height: "36lvh",marginTop:"-9.5lvh",marginLeft:"-2.2lvw"}}/>
            </Col>
            <Col span={7}>
              <div className={"new-card-sub-header cs-title"} style={{marginTop: 20}}>
                Verify your details
              </div>
              {(!isNewUser || (isNewUser && isEmailVerified === false)) && 
                <>
                  <div className="new-verify-email-container">
                    <span className="new-verification-heading">Email</span>
                    <Text className="new-card-sub-header" style={{textAlign: 'left'}}>
                        Please enter the verification code sent on email
                    </Text>
                    <OtpInput
                      value={emailOtp}
                      onChange={(value) => setEmailOtp(value)}
                      numInputs={6}
                      shouldAutoFocus={true}
                      isInputNum={true}
                      containerStyle="new-otp-container"
                      inputStyle="otp-input"
                    />
                  </div>
                  {(errorMsg && (!isNewUser || (isNewUser && isPhoneVerified !== false))) && (
                    <Title level={5} type="danger" style={{marginTop: 10}}>
                      {errorMsg}
                    </Title>
                  )}
                  <Text className="receive-otp-info" style={{display: 'block', textAlign: 'left'}}>
                    {"If you didn't receive the verification code, "} &nbsp;
                    {enableRetry ? (
                      <Link className="resend-otp-txt" onClick={resendEmailOtp}>
                        {"Re-send Code"}
                      </Link>
                    ) : (
                      <Text className="retry-count-txt">{`retry in ${timerCount
                        .toString()
                        .padStart(2, "0")} sec.`}</Text>
                    )}
                  </Text>
                </>
              }
              {(isNewUser && isPhoneVerified === false) && (
                <>
                  <div
                    className="new-verify-email-container"
                  >
                    <span className="new-verification-heading">Phone</span>
                    <Text className="new-card-sub-header" style={{textAlign: 'left'}}>
                        Please enter the verification code sent on phone
                    </Text>
                    <OtpInput
                      value={phoneOtp}
                      onChange={(value) => setPhoneOtp(value)}
                      numInputs={6}
                      shouldAutoFocus={true}
                      isInputNum={true}
                      containerStyle="new-otp-container"
                      inputStyle="otp-input"
                    />
                  </div>
                  <Text className="receive-otp-info" style={{display: 'block', textAlign: 'left'}}>
                    {"If you didn't receive the verification code, "} &nbsp;
                    {enableRetryPhone ? (
                      <Link className="resend-otp-txt" onClick={resendPhoneOtp}>
                        {"Re-send Code"}
                      </Link>
                    ) : (
                      <Text className="retry-count-txt">{`retry in ${timerCountPhone
                        .toString()
                        .padStart(2, "0")} sec.`}</Text>
                    )}
                  </Text>
                  {errorMsg && (
                    <Title level={5} type="danger" style={{marginTop: 10}}>
                      {errorMsg}
                    </Title>
                  )}
                </>
              )}
              <div className="new-verifyotp-btn-groups">
                <Button
                  type="primary"
                  htmlType="button"
                  size="medium"
                  className="new-gen-otp-btn"
                  disabled={isNewUser ? ((isEmailVerified === false && isPhoneVerified === false) ? 
                    (emailOtp.length !== 6 || phoneOtp.length !== 6) : isEmailVerified === false ? emailOtp.length !== 6 : phoneOtp.length !== 6) : emailOtp.length !== 6}
                  onClick={() => validateOtp()}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>

      <Modal
        centered={true}
        footer={null}
        closable={false}
        open={showModal}
        className="otp-section-modal"
        width={380}
      >
        <Space direction={"vertical"} className="reset-modal-space">
          <Title level={3} className="reset-modal-title">
            Your password has been reset!
          </Title>
          <Text color="#707070" className="reset-modal-sub-title">
            {
              "To ensure your account's security, you will need to log in to the dashboard with your updated credentials."
            }
          </Text>
        </Space>
      </Modal>
    </React.Fragment>
  );
};

export default NewVerifyOTP;
